// Libraries
import React from 'react';
import { Link } from 'gatsby';
import { GatsbyImage } from 'gatsby-plugin-image';
// Components
import Container from './container';
// Assets
import * as styles from './gallery-preview.module.css';

const GalleryPreview = ({ galleries }) => {
  if (!galleries) return null;
  if (!Array.isArray(galleries)) return null;

  return (
    <Container>
      <ul className={styles.galleryList}>
        {galleries.map((gallery) => {
          return (
            <li key={gallery.slug} className={styles.galleryListItem}>
              <Link to={`/gallery/${gallery.slug}`} className={styles.link}>
                <GatsbyImage alt="" image={gallery.heroImage.gatsbyImageData} />
                <h2 className={styles.title}>{gallery.title}</h2>
              </Link>
            </li>
          );
        })}
      </ul>
    </Container>
  );
};

export default GalleryPreview;
