// Libraries
import React from 'react';
import { graphql } from 'gatsby';
import get from 'lodash/get';

// Components
import Seo from '../components/seo';
import Layout from '../components/layout';
import Hero from '../components/hero';
import GalleryPreview from '../components/gallery-preview';

// Assets
import galleryImage from '../../public/gallery.webp';

class GalleryIndex extends React.Component {
  render() {
    const galleries = get(this, 'props.data.allContentfulGallery.nodes');

    return (
      <Layout location={this.props.location}>
        <Seo title="Gallery" />
        <Hero
          title="Gallery"
          content="Image gallery of creatures, heros, and exotic worlds..."
          image={galleryImage}
          useImageTag
        />
        <GalleryPreview galleries={galleries} />
      </Layout>
    );
  }
}

export default GalleryIndex;

export const pageQuery = graphql`
  query GalleryIndexQuery {
    allContentfulGallery {
      nodes {
        id
        title
        slug
        heroImage {
          gatsbyImageData(
            layout: FULL_WIDTH
            placeholder: BLURRED
            width: 424
            height: 212
          )
        }
      }
    }
  }
`;
